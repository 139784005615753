import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "dashboard" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "dashboard" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "dashboard" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
      // Pages
  {
    // Dashboard
    path: "/",
    name: "dashboard",
    meta: { title: "DashBoard", authRequired: true },
    component: () => import("../views/pages/dashboard"),
  },
  {
    // Xem thêm
    path: "/viewMore",
    name: "viewMore",
    meta: { title: "View More", authRequired: true },
    component: () => import("../views/pages/viewMore"),
  },




    // Nhân viên
  {
    path: "/employee",
    name: "employee",
    meta: { title: "Employee", authRequired: true },
    component: () => import("../views/employee/employee-manager"),
  },


    // Chi tiết nhân viên
    {
      path: "/employeeProfile-Detail",
      name: "employee Profile Detail",
      meta: { title: "Setup", authRequired: true },
      component: () => import("../views/employee/employeeProfile/employeeProfile-detail"),
    },

    // Chỉnh sửa nhân viên
    {
      path: "/employeeProfile-Setting",
      name: "employee Profile Setting",
      meta: { title: "Setup", authRequired: true },
      component: () => import("../views/employee/employeeProfile/employeeProfile-setting"),
    },

    // Event - Sự kiện
  {
    path: "/birthday",
    name: "birthday",
    meta: { title: "Birthday", authRequired: true },
    component: () => import("../views/event/birthday-management"),
  },


  // Tuyển dụng
  {
    path: "/Recruitment/Profile",
    name: "Profile Management",
    meta: { title: "Profile Management", authRequired: true },
    component: () => import("../views/recruitment/profile-management"),
  },
  {
    path: "/Recruitment/Interview",
    name: "Interview Management",
    meta: { title: "Interview Management", authRequired: true},
    component: () => import("../views/recruitment/interview-management")
  },
  {
    path: "/Recruitment/Job",
    name: "Job Management",
    meta: { title: "Job Management", authRequired: true},
    component: () => import("../views/recruitment/job-management")
  },

  //Đề xuất
  {
    // Setup vị trí
    path: "/setup",
    name: "setup",
    meta: { title: "Setup", authRequired: true },
    component: () => import("../views/proposed/setup"),
  },

  // Quản lý tổ chức
  {
    path: "/Organization/Quick-Create",
    name: "Quick Create",
    meta: { title: "Quick Create", authRequired: true },
    component: () => import("../views/organization/quick-create"),
  },

  {
    path: "/Organization/Block",
    name: "Block List",
    meta: { title: "Block List", authRequired: true },
    component: () => import("../views/organization/block/block-list"),
  },

  {
    path: "/Organization/Company",
    name: "Company List",
    meta: { title: "Company List", authRequired: true },
    component: () => import("../views/organization/company/company-list"),
  },
  {
    path: "/Organization/Company-Detail",
    name: "Company Detail",
    meta: { title: "Company Detail", authRequired: true },
    component: () => import("../views/organization/company/company-detail"),
  },
  {
    path: "/Organization/Company-Create",
    name: "Company Create",
    meta: { title: "Company Create", authRequired: true },
    component: () => import("../views/organization/company/company-create"),
  },
  {
    path: "/Organization/Company-Edit",
    name: "Company Edit",
    meta: { title: "Company Edit", authRequired: true },
    component: () => import("../views/organization/company/company-edit"),
  },


  {
    path: "/Organization/Department",
    name: "Department List",
    meta: { title: "Department List", authRequired: true },
    component: () => import("../views/organization/department/department-list"),
  },
  {
    path: "/Organization/Department-Detail",
    name: "Department Detail",
    meta: { title: "Department Detail", authRequired: true },
    component: () => import("../views/organization/department/department-detail"),
  },


  {
    path: "/Organization/Group",
    name: "Group List",
    meta: { title: "Group List", authRequired: true },
    component: () => import("../views/organization/group/group-list"),
  },
  {
    path: "/Organization/Group-Detail",
    name: "Group Detail",
    meta: { title: "Group Detail", authRequired: true },
    component: () => import("../views/organization/group/group-detail"),
  },


  // Thông tin cá nhân
  {
    path: "/Profile/Setting",
    name: "profileSetting",
    meta: { title: "Profile Setting", authRequired: true },
    component: () => import("../views/profile/profile-setting"),
  },
  {
    path: "/Profile/Detail",
    name: "profileDetail",
    meta: { title: "Profile Detail", authRequired: true },
    component: () => import("../views/profile/profile-detail"),
  },
];